import { useEffect, useState } from 'react'

import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  onSnapshot
} from 'firebase/firestore'

const TICKET_AMOUNT = 4000

function BuyTickets ({ db }) {
  const [showLoading, setShowLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)

  const [webpayPlusToken, setWebpayPlusToken] = useState('')
  const [webpayPlusPaymentFormUrl, setWebpayPlusPaymentFormUrl] = useState('')
  const [termsChecked, setTermsChecked] = useState(false)
  const [paying, setPaying] = useState(false)

  const [detailTicketData, setDetailTicketData] = useState(null)

  const todayArray = new Date().toLocaleDateString('es-CL', { timeZone: 'America/Santiago' }).split('-')
  const today = `${todayArray[2]}-${todayArray[1]}-${todayArray[0]}`

  useEffect(() => {
    if (currentStep === 1 && detailTicketData) {
      document.querySelector('#ticketDate').value = detailTicketData.date
      document.querySelector('#ticketNumberOfTickets').value = detailTicketData.numberOfTickets
      document.querySelector('#ticketFirstName').value = detailTicketData.firstName
      document.querySelector('#ticketLastName').value = detailTicketData.lastName
      document.querySelector('#ticketEmail').value = detailTicketData.email
      document.querySelector('#ticketConfirmEmail').value = detailTicketData.email
    }
  }, [currentStep])

  const handleAddTicket = () => {
    const numberOfTicketsInput = document.querySelector('#ticketNumberOfTickets')
    const numberOfTickets = parseInt(numberOfTicketsInput.value)
    numberOfTicketsInput.value = numberOfTickets + 1
  }

  const handleReduceTicket = () => {
    const numberOfTicketsInput = document.querySelector('#ticketNumberOfTickets')
    const numberOfTickets = parseInt(numberOfTicketsInput.value)
    if (numberOfTickets > 1) {
      numberOfTicketsInput.value = numberOfTickets - 1
    }
  }

  const handleSubmitTicketsForm = async (event) => {
    event.preventDefault()

    const form = event.target
    const formData = new FormData(form)
    const {
      date,
      numberOfTickets,
      firstName,
      lastName,
      email,
      confirmEmail
    } = Object.fromEntries(formData)

    if (date < today) {
      document.querySelector('#ticketDate').setCustomValidity('la fecha debe ser mayor o igual a la fecha actual')
      document.querySelector('#ticketDate').reportValidity()
      return
    }

    if (email !== confirmEmail) {
      document.querySelector('#ticketConfirmEmail').setCustomValidity('Correo electrónico no coincidente')
      document.querySelector('#ticketConfirmEmail').reportValidity()
      return
    }

    if (!form.checkValidity()) {
      return alert('Datos inválidos')
    }

    setShowLoading(true)

    const docRef = await addDoc(collection(db, 'tickets'), {
      date,
      numberOfTickets,
      firstName,
      lastName,
      email,
      createdAt: serverTimestamp()
    })

    onSnapshot(doc(db, 'tickets', docRef.id), (doc) => {
      const ticket = doc.data()

      if (ticket.webpay_plus_form) {
        setWebpayPlusToken(ticket.webpay_plus_form.token)
        setWebpayPlusPaymentFormUrl(ticket.webpay_plus_form.url)

        setDetailTicketData({
          date,
          numberOfTickets,
          firstName,
          lastName,
          email
        })

        setCurrentStep(2)
        setShowLoading(false)
      }
    })
  }

  const handleCheckTerms = (event) => {
    const checked = event.target.checked
    setTermsChecked(checked)
  }

  const handleSubmitPay = () => {
    setPaying(true)
  }

  return (
    <>
      <div className="container pt-3" style={{ maxWidth: '992px' }}>
        <div className="card">
          <div className='card-title d-flex justify-content-center align-items-center'>
            <img src="parquechina.png" alt="..."
              className="card-img-top"
              style={{ objectFit: 'cover', height: '150px' }} />
            <img src="parquechina_logo.svg" alt="..."
              className='p-2 rounded-lg'
              style={{ position: 'absolute', backdropFilter: 'blur(10px)', height: '90px', maxWidth: '90%' }} />
          </div>
          <div className="card-body">
            {showLoading
              ? <>
                  <div className="d-flex justify-content-center align-items-center">
                    <h2 style={{ color: '#dd3030' }}>
                      Cargando...
                    </h2>
                    <div className="spinner-border ms-4" role="status"
                      style={{ color: '#dd3030' }}>
                      <span className="visually-hidden">Cargando...</span>
                    </div>
                  </div>
                </>
              : <>
                {currentStep === 1 &&
                <>
                  <div className="alert alert-primary d-flex align-items-center" role="alert">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="me-2" viewBox="0 0 16 16">
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/>
                    </svg>
                    <div>
                      Si almuerzas primero en el restaurant, la entrada es liberada.
                    </div>
                  </div>
                  <form className='row' onSubmit={handleSubmitTicketsForm}>
                    <h2 className='mb-3'>
                      Entradas Parque China
                    </h2>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="ticketDate" className="form-label col-form-label-lg">
                        <b>Fecha</b>
                      </label>
                      <input id="ticketDate" name="date" type="date"
                        className="form-control form-control-lg" min={today} required
                        onChange={(event) => { event.target.setCustomValidity('') }} />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="ticketNumberOfTickets" className="form-label col-form-label-lg">
                        <b>Número de entradas</b>
                      </label>
                      <div className="input-group mb-3">
                        <button className="btn btn-primary" type="button"
                          onClick={handleReduceTicket}
                          style={{ backgroundColor: '#dd3030', borderColor: '#dd3030' }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/>
                          </svg>
                        </button>
                        <input id="ticketNumberOfTickets" name="numberOfTickets" type="number"
                          className="form-control form-control-lg" min="1" defaultValue={1} required />
                        <button className="btn btn-primary" type="button"
                          onClick={handleAddTicket}
                          style={{ backgroundColor: '#dd3030', borderColor: '#dd3030' }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                          </svg>
                        </button>
                      </div>
                      <div id="passwordHelpBlock" className="form-text">
                      <b>Valor de entrada: {new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(TICKET_AMOUNT)} por persona (Niños también pagan)</b>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="ticketFirstName" className="form-label col-form-label-lg">
                        <b>Nombre</b>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                          </svg>
                        </span>
                        <input id="ticketFirstName" name="firstName" type="text"
                          className="form-control form-control-lg" required />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="ticketLastName" className="form-label col-form-label-lg">
                        <b>Apellido</b>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                          </svg>
                        </span>
                        <input id="ticketLastName" name="lastName" type="text"
                          className="form-control form-control-lg" required />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="ticketEmail" className="form-label col-form-label-lg">
                        <b>Correo electrónico</b>
                      </label>
                      <div className="input-group">
                      <span className="input-group-text">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                        </svg>
                      </span>
                      <input id="ticketEmail" name="email" type="email"
                        className="form-control form-control-lg" required />
                      </div>
                    </div>
                    <div className="col-md-6 mb-5">
                      <label htmlFor="ticketConfirmEmail" className="form-label col-form-label-lg">
                        <b>Confirmar correo electrónico</b>
                      </label>
                      <div className="input-group">
                      <span className="input-group-text">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                        </svg>
                      </span>
                      <input id="ticketConfirmEmail" name="confirmEmail" type="email"
                        className="form-control form-control-lg" required
                        onChange={(event) => { event.currentTarget.setCustomValidity('') }} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-lg btn-primary w-100"
                        style={{ backgroundColor: '#dd3030', borderColor: '#dd3030' }}>
                        Confirmar
                      </button>
                    </div>
                  </form>
                </>
                }

                {currentStep === 2 &&
                <>
                  <form method="POST" action={webpayPlusPaymentFormUrl}
                    onSubmit={handleSubmitPay}>
                    <input type="hidden" name="token_ws" value={webpayPlusToken} />

                    <h2 className="mb-3">
                      Confirmar compra
                    </h2>

                    <div className='mb-3'>
                      <ul className="list-group">
                        <li className="list-group-item d-flex justify-content-between lh-sm">
                          <div>
                            <h5 className="my-0 text-lg">Fecha</h5>
                            <span className="text-body-secondary">
                              {detailTicketData.date}
                            </span>
                          </div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between lh-sm">
                          <div>
                            <h5 className="my-0">Número de entradas</h5>
                            <span className="text-body-secondary">
                              {detailTicketData.numberOfTickets}
                            </span>
                          </div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between lh-sm">
                          <div>
                            <h5 className="my-0">Nombre</h5>
                            <span className="text-body-secondary">
                              {detailTicketData.firstName} {detailTicketData.lastName}
                            </span>
                          </div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between lh-sm">
                          <div>
                            <h5 className="my-0">Correo electrónico</h5>
                            <span className="text-body-secondary">
                              {detailTicketData.email}
                            </span>
                          </div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                          <span><h5>Total</h5></span>
                          <span><h5>{new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(detailTicketData.numberOfTickets * TICKET_AMOUNT)}</h5></span>
                        </li>
                      </ul>

                      <small>
                        * No se hacen cambios ni devoluciones.
                      </small>
                    </div>

                    <div className="mb-5">
                      <div className="form-check form-control-lg">
                        <input id="termsCheck" type="checkbox"
                          className="form-check-input"
                          onChange={handleCheckTerms} />
                        <label className="form-check-label" htmlFor="termsCheck">
                          Acepto los {' '}
                          <a href="http://parquechina.cl" target="_blank" rel="noreferrer">
                            términos y condiciones
                          </a>
                          .
                        </label>
                      </div>
                    </div>

                    <div>
                      <button type="submit" className="btn btn-lg btn-primary w-100 mb-2"
                        style={{ backgroundColor: '#dd3030', borderColor: '#dd3030' }}
                        disabled={!termsChecked}>
                        Pagar
                      </button>
                      {!paying && (
                        <button type="button" className="btn btn-link w-100"
                          onClick={() => { setPaying(false); setTermsChecked(false); setCurrentStep(1) }}>
                          Volver
                        </button>
                      )}
                    </div>
                  </form>
                </>
                }
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default BuyTickets
